// import { fetchUserEmailById } from 'Client/services/user';
import { getUserById } from 'Client/services/user/findUserById.gql';

export const getInitEmailInput = async (
  storedUserId: string | undefined,
  signupEmail: string | undefined,
  apiToken: string
): Promise<string> => {
  return (
    signupEmail || (await getUserById(storedUserId, apiToken))?.email || ''
  );
};
