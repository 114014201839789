import { NextRouter } from 'next/router';
import { captureException, captureMessage } from '@sentry/node';
import { v4 as uuidv4 } from 'uuid';
import { mixpanel } from 'Client/utils/hooks/useAnalytics/mixpanel';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { MixpanelEventTypes } from 'Client/utils/hooks';
import {
  Contribution,
  ContributionStatus,
  ContributionType,
} from 'Shared/types/contribution';
import { makeAnswersForGaudi } from 'Client/pages/proposals/utils';
import { Answers, Question } from 'Client/pages/proposals/types';
import { ProjectGaudi, ProjectProps, User } from 'Shared/types';
import {
  createContributionGaudi,
  saveCommentFirstTime,
  updateContribution,
} from '../contributions';
import { checkOnUserLanguage, getCurrentUserId } from '../user';
import { handleProposalEndFlow } from './';

type Params = {
  storedUserId: string | undefined;
  footerEmail: string | undefined;
  signupEmail: string | undefined;
  contributionId: string | undefined;
  project: ProjectGaudi & ProjectProps;
  user: User | null;
  proposalId: string | undefined;
  proposalSlug: string | undefined;
  answers: Answers;
  questions?: Array<Question> | null;
  router: NextRouter;
  dispatch: (actinObj: Record<string, unknown>) => void;
  numOfProposalSteps: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feelingAnswer?: Record<string, any> | undefined;
  confEmailSent: string | null;
  setLocalItem: (k: string, v: string) => void;
  voiceAnswers?: Record<string, string>;
  transcribeOnly: boolean;
  apiToken: string;
};

const makeGaudiContr = ({
  questions,
  answers,
  id,
  project,
  proposalSlug,
  language,
  user,
  userId,
}) => {
  const gaudiAnswers = questions ? makeAnswersForGaudi(questions, answers) : {};
  const contrDataForGaudi = {
    _id: id, // it is converted to an ObjectId on POST
    project,
    schemeId: 'proposals',
    sectionId: proposalSlug,
    language,
    status: user
      ? ContributionStatus.CONFIRMED
      : userId
      ? ContributionStatus.PENDING
      : ContributionStatus.ANONYMOUS,
    user_id: userId,
    origin: 'commonplace',
    survey: false,
    type: ContributionType.COMMENT,
    date: new Date(),
    syncHash: uuidv4(),
    ...gaudiAnswers,
  };
  return contrDataForGaudi;
};

export const handleSubmitComment = async ({
  storedUserId,
  footerEmail,
  signupEmail,
  contributionId,
  project,
  user,
  proposalId,
  proposalSlug,
  answers,
  questions,
  router,
  dispatch,
  numOfProposalSteps,
  feelingAnswer,
  confEmailSent,
  setLocalItem,
  voiceAnswers,
  transcribeOnly,
  apiToken,
}: Params): Promise<{ isGaming: boolean; id?: string }> => {
  console.time('ContributionFlow - [1.2] - handleSubmitComment');
  try {
    console.time(
      'ContributionFlow - [1.2] - handleSubmitComment - Existent contribution'
    );
    console.time(
      'ContributionFlow - [1.2] - handleSubmitComment - New contribution'
    );

    const userId = await getCurrentUserId(
      user,
      storedUserId,
      footerEmail || signupEmail,
      project.features.userEndpointsOnGql,
      apiToken
    );
    let contr: Contribution;
    if (contributionId) {
      const acornContributionData = {
        answers,
        language: router.locale,
        draft: false,
      };
      if (project?.features?.trackContributionFlow) {
        mixpanel(
          user,
          project as ProjectGaudi & ProjectProps,
          router.locale
        ).trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
          fileName:
            'src/client/services/contributionFlow/handleSubmitComment.ts',
          functionName: 'handleSubmitComment',
          database: 'acorn',
          fieldToBeUpdated: Object.keys(acornContributionData),
          gaudiUpdate: null,
          acornUpdate: acornContributionData,
          userId: 'none',
          demographicsId: 'none',
          contributionId: contributionId,
        });
      }
      await updateContribution(
        contributionId,
        acornContributionData,
        false,
        apiToken
      );
      console.timeEnd(
        'ContributionFlow - [1.2] - handleSubmitComment - Existent contribution'
      );
    } else {
      // saving for the first time (eg when all answers were empty until the last step)
      contr = await saveCommentFirstTime({
        project,
        user,
        proposalId,
        proposalSlug,
        answers,
        voiceAnswers,
        draft: false,
        userId,
        language: router.locale,
        externalParams: router.query as unknown as {
          responseID: string;
          source: string;
        },
        transcribeOnly,
      });
      if (contr?.gaming) return { isGaming: true };
      if (contr) {
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_ID,
          contributionId: contr?._id,
        });
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_TYPE,
          contributionType: ContributionType.COMMENT,
        });
      }
      await checkOnUserLanguage({
        user,
        i18nLang: router.locale,
        userEndpointsOnGql: project.features.userEndpointsOnGql,
        apiToken,
      });
      console.timeEnd(
        'ContributionFlow - [1.2] - handleSubmitComment - New contribution'
      );
    }
    // saving contribution to gaudi db
    const contrDataForGaudi = makeGaudiContr({
      questions,
      answers,
      id: contributionId || contr?._id,
      project: project.id,
      proposalSlug,
      language: router.locale,
      user,
      userId,
    });
    const { data: createdGaudiContribution } = await createContributionGaudi(
      contrDataForGaudi,
      apiToken
    );
    if (project?.features?.trackContributionFlow) {
      mixpanel(
        user,
        project as ProjectGaudi & ProjectProps,
        router.locale
      ).trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
        fileName: 'src/client/services/contributionFlow/handleSubmitComment.ts',
        functionName: 'handleSubmitComment',
        database: 'gaudi',
        fieldToBeUpdated: Object.keys(contrDataForGaudi),
        gaudiUpdate: contrDataForGaudi,
        acornUpdate: null,
        userId: contrDataForGaudi?.user_id,
        demographicsId: 'none',
        contributionId: contrDataForGaudi._id || createdGaudiContribution?._id,
      });
    }
    if (!contributionId) {
      captureMessage('no contributionId when adding comment', {
        tags: {
          file: 'handleSubmitComment',
          function: 'handleSubmitComment',
        },
        user: {
          id: userId,
          email: user?.email,
          name: user?.firstName,
        },
      });
    }
    mixpanel(user, project, router.locale).trackEvent(
      MixpanelEventTypes.ADDED_COMMENT,
      {
        path: router.asPath,
        contributionId: contributionId || contr?._id,
        feeling: feelingAnswer ? feelingAnswer.value : 'N/A',
        proposalSteps: numOfProposalSteps,
      }
    );
    await handleProposalEndFlow({
      project,
      user,
      storedUserId,
      proposalSlug,
      router,
      contributionId: contributionId || contr?._id,
      footerEmail,
      signupEmail,
      confEmailSent,
      setLocalItem,
      apiToken,
    });
    console.timeEnd('ContributionFlow - [1.2] - handleSubmitComment');

    return {
      id: contributionId || contr?._id,
      isGaming: false,
    };
  } catch (error) {
    captureException(
      `Error in handleSubmitComment @ handleSubmitComment.ts: ${error}`
    );
  } finally {
    console.timeEnd('ContributionFlow - [1.2] - handleSubmitComment');
  }
};
