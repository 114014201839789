import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { UPLOAD_FILE_TO_S3 } from 'Client/utils/gql/mutations.gql';
import { SeoMetaInfo } from 'Atoms';
import { ProposalPagesTemplate as Template } from 'Templates';
import {
  useProject,
  useUser,
  useAnalytics,
  MixpanelEventTypes,
  useProposalContext,
} from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { handleSubmitComment } from 'Client/services/contributionFlow';
import { addNewQueryParam, removeQueryParam } from 'Client/utils/url';
import {
  getLocalItem,
  setLocalItem,
  CONFIRMATION_EMAIL_SENT_ITEM,
  CONTRIBUTION_SESSION_ITEM,
} from 'Client/utils/localstorage';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { BlockContentStages, ConfirmationPageProps } from './types';
import { ConfirmationPageContent, FullWidthContainer } from './components';
import { areNoQuestionsAnswered, makeMappedAnswers } from './utils';

/**
 * @deprecated
 * Currently only used when a project has feature flag `skipConfirmPageCF: false` (which is 0 in prod).
 * Accessed in /proposals/{slug}/confirm, it used to be used to also edit the user's answers before submitting.
 * This code is probably so outdated it should be rewritten from scatch.
 */

export const ConfirmationPage: React.FC<ConfirmationPageProps> = ({
  proposalId,
  proposalTitle,
  proposalSlug,
  proposalStage,
  questions,
  numOfProposalSteps,
  prefillAnswersFromContribution,
  ...props
}: ConfirmationPageProps) => {
  const [
    {
      answers,
      voiceAnswers,
      contributionId,
      customQuestionOptions,
      signupEmail,
      transcribeOnly,
    },
    dispatch,
  ] = useProposalContext();
  const { apiToken } = useUtils();
  const project = useProject();
  const { user } = useUser();
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const [editComment, setEditComment] = React.useState(false);
  const [mappedAnswers, setMappedAnswers] = React.useState([]);
  const [activeProjectProposal, setActiveProjectProposal] =
    React.useState(false);
  const [uploadToS3] = useMutation(UPLOAD_FILE_TO_S3);

  React.useEffect(() => {
    const check = !(
      BlockContentStages.includes(proposalStage) ||
      BlockContentStages.includes(project.stage)
    );
    setActiveProjectProposal(check);
  }, [proposalStage, project]);

  React.useEffect(() => {
    trackEvent(MixpanelEventTypes.VIEW_PAGE, {
      url: `${router.basePath}${router.asPath}`,
      hasAnswers: !areNoQuestionsAnswered(answers),
    });
  }, []);

  React.useEffect(() => {
    if (router.query?.editComment) {
      setEditComment(true);
    } else {
      setEditComment(false);
    }
  }, [router.query]);

  React.useEffect(() => {
    if (questions && router.query?.cid) {
      // initialize with existing answers if query id present
      prefillAnswersFromContribution(router.query.cid as string);
    }
  }, [questions, router.query]);

  React.useEffect(() => {
    if (editComment) {
      // if editing the comment, do not show footer/header
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_FOOTER,
        showFooter: false,
      });
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_HEADER,
        showHeader: false,
      });
    } else {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_FOOTER,
        showFooter: true,
      });
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_HEADER,
        showHeader: true,
      });
    }
  }, [editComment]);

  React.useEffect(() => {
    if (activeProjectProposal && Object.keys(answers).length > 0) {
      const mappedAnswersNew = makeMappedAnswers({
        answers,
        questions,
        customQuestionOptions,
      });
      setMappedAnswers(mappedAnswersNew);
    }
  }, [activeProjectProposal, customQuestionOptions, answers, questions]);

  const triggerSubmitComment = async (footerEmail?: string) => {
    const feelingAnswer = mappedAnswers.find(
      (answer) => answer.name === 'feeling'
    );
    const { id } = await handleSubmitComment({
      storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
      footerEmail,
      signupEmail,
      contributionId,
      project,
      user,
      proposalId,
      proposalSlug,
      answers,
      voiceAnswers: voiceAnswers as Record<string, string>,
      questions,
      router,
      dispatch,
      numOfProposalSteps,
      feelingAnswer,
      confEmailSent: getLocalItem(CONFIRMATION_EMAIL_SENT_ITEM),
      setLocalItem,
      transcribeOnly,
      apiToken,
    });

    await Promise.all(
      Object.keys(voiceAnswers || {}).map(async (key) => {
        const blob = await fetch(voiceAnswers[key] as string).then((r) =>
          r.blob()
        );
        const form = new FormData();
        form.append('file', blob, `${id}_${key}.wav`);

        uploadToS3({ variables: { file: form.get('file') } });
      })
    );
  };

  const handleEditComment = () => {
    const queryParams = addNewQueryParam(router.asPath, 'editComment', 'true');
    router.replace(`/proposals/${proposalSlug}/confirm${queryParams}`);
  };

  const handleExitEditComment = (noAnswers: boolean) => {
    const path = noAnswers ? 'empty-answers' : 'confirm';
    const params = removeQueryParam(router.asPath, 'editComment');
    router.replace(`/proposals/${proposalSlug}/${path}${params}`);
  };

  return (
    <Template
      proposalTitle={proposalTitle}
      proposalSlug={proposalSlug}
      onSubmitComment={triggerSubmitComment}
      {...props}
    >
      <FullWidthContainer>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <SeoMetaInfo
          projectStage={project.stage}
          projectName={project.name}
          page="proposal"
        />
        {activeProjectProposal && (
          <ConfirmationPageContent
            editComment={editComment}
            handleEditComment={handleEditComment}
            mappedAnswers={mappedAnswers}
            proposalSlug={proposalSlug}
            contributionId={contributionId}
            exitEditComment={handleExitEditComment}
          />
        )}
      </FullWidthContainer>
    </Template>
  );
};
