import * as React from 'react';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { EQUALS } from 'Shared/constants';
import { usePermissions, useProject } from 'Client/utils/hooks';
import { HubHeader } from 'Organisms';
import { Permissions } from 'Client/constants/permissions';
import { DRAFT_PROPOSALS } from 'Pages/edit/components/Editor/DynamicProposalsEditor/constants';
import { ProposalStage } from 'Client/pages/proposals/types';
import { getExternalProposals } from 'Client/services/proposals/getExternalProposals';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { ProposalsReel, CustomProposalCard } from './components';
import { DynamicProposalsProps } from './types';

const removeProposalsWithoutContent = (proposal) =>
  proposal && proposal.pageContent;
const removeInactiveProposals = (proposal) => proposal && proposal.active;

export const DynamicProposals: React.FC<DynamicProposalsProps> = ({
  content,
  ignoreHidden = false,
}: DynamicProposalsProps) => {
  const { can } = usePermissions();
  const { showCardsCounter, label, order, rules, unselectedProposals } =
    content;
  const project = useProject();
  const { apiToken } = useUtils();
  const { i18n } = useTranslation();
  const [proposals, setProposals] = React.useState([]);
  const [filteredProposals, setFilteredProposals] = React.useState([]);
  const canSeeDraftProposals = can(Permissions.SEE_PROJECT_BEFORE_LAUNCH);
  const rulesHasOnlyDraftProposals = rules.some((rule) => {
    const { condition, value } = rule;
    return condition === EQUALS && value === DRAFT_PROPOSALS;
  });

  React.useEffect(() => {
    const filtered = proposals
      .filter(removeInactiveProposals)
      .filter(removeProposalsWithoutContent)
      .filter((proposal) => {
        if (
          rulesHasOnlyDraftProposals ||
          proposal.stage === ProposalStage.DRAFT
        ) {
          return canSeeDraftProposals;
        }
        return true;
      });
    setFilteredProposals(filtered);
  }, [proposals, rulesHasOnlyDraftProposals]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getExternalProposals({
          rules,
          listOrder: order,
          language: i18n.language,
          unselectedProposals,
          editModeOptions: false,
          ignoreHidden,
          externalProposalsRequestOnGql:
            project.features.externalProposalsRequestOnGql,
          projectId: project._id,
          apiToken,
        });

        setProposals(response || []);
      } catch (error) {
        captureException(
          `error in fetchData @ DynamicProposals.tsx : ${error}`
        );
      }
    };

    fetchData();
  }, [order, rules, unselectedProposals]);

  if (rulesHasOnlyDraftProposals && !canSeeDraftProposals) return null;
  return (
    <>
      <HubHeader title={label} anchorText="" path="" />
      <ProposalsReel
        proposalsCount={filteredProposals.length}
        showCardsCounter={showCardsCounter}
      >
        {filteredProposals.map((proposal, index) => (
          <CustomProposalCard
            key={index}
            proposal={proposal}
            data-testid="dynamic-proposal-card"
          />
        ))}
      </ProposalsReel>
    </>
  );
};
