import * as React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { GamingBanner } from 'Organisms';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { useProposalContext } from 'Client/utils/hooks';
import { Footer, Masthead } from './components';
import { ProposalPagesTemplateProps } from './types';
import { HeadMeta } from '../components/HeadMeta';

export const ProposalPagesTemplate: React.FC<ProposalPagesTemplateProps> = ({
  proposalTitle,
  proposalSlug,
  children,
  contributionsNumber,
  onSaveDraftComment,
  onSubmitComment,
  proposalHasQuestions,
  openGraphInfo,
  showGamingBanner,
  hideQuestion,
  currentStep,
  printPage,
  questions,
}: ProposalPagesTemplateProps) => {
  const [
    { showHeader: rdxShowHeader, showFooter, isGamingBannerOpen },
    dispatch,
  ] = useProposalContext();
  const router = useRouter();

  const isEmbed = router.query?.embed === 'true';
  const showHeader = isEmbed ? false : rdxShowHeader;

  return (
    <Wrapper data-testid="ProposalPagesTemplate">
      <HeadMeta openGraphInfo={openGraphInfo} />
      {showHeader && (
        <Masthead
          title={proposalTitle}
          contributionsNumber={contributionsNumber}
          slug={proposalSlug}
          printPage={printPage}
        />
      )}
      <Content showHeader={showHeader}>{children}</Content>
      {showGamingBanner && isGamingBannerOpen && (
        <GamingBanner
          onClose={() => {
            dispatch({
              type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
              isOpen: false,
            });
          }}
        />
      )}
      {proposalSlug && showFooter && !currentStep?.hideProposalFooter && (
        <Footer
          slug={proposalSlug}
          hideQuestion={hideQuestion}
          onSaveDraftComment={onSaveDraftComment}
          onSubmitComment={onSubmitComment}
          proposalHasQuestions={proposalHasQuestions}
          questions={questions}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.white[500]};
`;

const Content = styled.div<{ showHeader?: boolean; showFooter?: boolean }>`
  margin: 0 auto;
  margin-bottom: ${({ showFooter, theme }) =>
    showFooter && theme.proposals.footer.height};
  display: inline-block;
  width: 100%;
`;

export default ProposalPagesTemplate;
