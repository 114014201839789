import { NextRouter } from 'next/router';
import { captureException } from '@sentry/node';
import { ContributionType } from 'Shared/types/contribution';
import { ProjectGaudi, ProjectProps, User } from 'Shared/types';
import { CONFIRMATION_EMAIL_SENT_ITEM } from 'Client/utils/localstorage';
import { changeRouteKeepParams } from 'Client/utils/url';
import { fetchDemographicsByUserByProject } from '../demographics';
import { sendConfirmationEmail } from '../email';
import { fetchUserByEmail } from '../user';
import { getUserById } from '../user/findUserById.gql';

type Params = {
  project: ProjectGaudi & ProjectProps;
  user?: User | null;
  storedUserId: string | undefined;
  proposalSlug: string;
  router: NextRouter;
  contributionId: string | undefined;
  footerEmail?: string | undefined;
  signupEmail?: string | undefined;
  confEmailSent: string | null;
  setLocalItem: (k: string, v: string) => void;
  isMap?: boolean;
  apiToken: string;
};

// handles all the redirects in the end of the contribution flow for proposals
export const handleProposalEndFlow = async ({
  project,
  user,
  storedUserId,
  proposalSlug,
  router,
  contributionId,
  footerEmail,
  signupEmail,
  confEmailSent,
  setLocalItem,
  isMap = false,
  apiToken,
}: Params): Promise<void> => {
  console.time('ContributionFlow - [1.3] - handleProposalEndFlow');
  console.time(
    'ContributionFlow - [1.3] - handleProposalEndFlow - With user - With demographics'
  );
  console.time(
    'ContributionFlow - [1.3] - handleProposalEndFlow - With user - Without demographics'
  );

  console.time(
    'ContributionFlow - [1.3] - handleProposalEndFlow - Without user - With email and demographics'
  );
  console.time(
    'ContributionFlow - [1.3] - handleProposalEndFlow - Without user - With email without demographics'
  );

  console.time(
    'ContributionFlow - [1.3] - handleProposalEndFlow - Without user - Without email'
  );
  try {
    if (user) {
      // user is logged in
      const userDemographics = await fetchDemographicsByUserByProject(
        user.id,
        project.id
      );
      if (userDemographics && !userDemographics.redirectRequired) {
        const thanksRoute = changeRouteKeepParams(
          router.asPath,
          `/${isMap ? 'map' : 'proposals'}/${proposalSlug}/thanks`
        );
        console.timeEnd(
          'ContributionFlow - [1.3] - handleProposalEndFlow - With user - With demographics'
        );

        router.push(thanksRoute);
      } else {
        const demographicsRoute = changeRouteKeepParams(
          router.asPath,
          '/demographics'
        );
        console.timeEnd(
          'ContributionFlow - [1.3] - handleProposalEndFlow - With user - Without demographics'
        );

        router.push(demographicsRoute);
      }
    } else {
      // user is logged out
      const email = footerEmail || signupEmail;
      const contributionUserId =
        storedUserId ||
        (
          await fetchUserByEmail(
            email,
            project.features.userEndpointsOnGql,
            apiToken
          )
        )?._id ||
        undefined;
      if (contributionUserId) {
        // email is provided in the email panel within the last 30min
        const contributionEmail =
          footerEmail ||
          signupEmail ||
          (await getUserById(contributionUserId, apiToken))?.email;
        const userLang = (await getUserById(contributionUserId, apiToken))
          ?.language;
        if (!confEmailSent || confEmailSent !== contributionUserId) {
          const res = await sendConfirmationEmail({
            email: contributionEmail,
            contributionId,
            contributionType: ContributionType.COMMENT,
            lang: userLang || router.locale,
          });
          if (res && res?.user && res?.user?._id) {
            setLocalItem(CONFIRMATION_EMAIL_SENT_ITEM, res.user._id);
          }
        }
        const userDemographics = await fetchDemographicsByUserByProject(
          contributionUserId,
          project.id
        );
        if (userDemographics && !userDemographics.redirectRequired) {
          const confirmEmailRoute = changeRouteKeepParams(
            router.asPath,
            '/confirm-email'
          );
          console.timeEnd(
            'ContributionFlow - [1.3] - handleProposalEndFlow - Without user - With email and demographics'
          );
          router.push(confirmEmailRoute);
        } else {
          const demographicsRoute = changeRouteKeepParams(
            router.asPath,
            '/demographics'
          );
          console.timeEnd(
            'ContributionFlow - [1.3] - handleProposalEndFlow - Without user - With email without demographics'
          );
          router.push(demographicsRoute);
        }
      } else {
        // cookie not stored (email is not provided after answering a question)
        const emailRoute = changeRouteKeepParams(router.asPath, '/email');
        console.timeEnd(
          'ContributionFlow - [1.3] - handleProposalEndFlow - Without user - Without email'
        );
        router.push(emailRoute);
      }
    }
  } catch (error) {
    captureException(error);
  } finally {
    console.timeEnd('ContributionFlow - [1.3] - handleProposalEndFlow');
  }
};
